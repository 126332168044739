import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next/client";
import { useRouter, useSearchParams } from "next/navigation";
import useSWR, { useSWRConfig } from "swr";

import { toast } from "@/components/ui/use-toast";
import { swrFetcher } from "@/lib/hooks/fetch-client";
import { Response, User } from "@/types/index";

import { fetchWrapper } from "./fetch-client";

export const cookieAge = 60 * 60 * 24 * 365; // 365 days

interface ResetPasswordProps {
	setErrors: (errors: { [key: string]: string }) => void;
	password: string;
	confirmPassword: string;
}

export const useAuth = ({ redirectIfAuthenticated }: any = {}) => {
	const router = useRouter();
	const searchParams = useSearchParams();
	const token = getCookie("token");

	const { mutate } = useSWRConfig();
	const { error } = useSWR<User>(token ? ["/v1/user", token] : null, swrFetcher);

	const csrf = () => fetchWrapper("/sanctum/csrf-cookie");

	const register = async ({ code: companyCode, setErrors, ...props }: any) => {
		await csrf();
		setErrors([]);
		const isCompanySignup = searchParams.has("code");

		try {
			const res:
				| Response<{ token: string }>
				| { errors: { [key: string]: string[] }; message: string } = await fetchWrapper(
				"/register",
				{
					method: "POST",
					body: JSON.stringify(props),
				}
			);

			if ("success" in res) {
				const token = res.data.token;

				setCookie("token", token, { path: "/", maxAge: cookieAge });

				// Add user to organization
				if (token && companyCode) {
					try {
						const res = await addToOrganization(token, companyCode);
						//result.company = res.code;
						deleteCookie("company-code");
					} catch (err) {
						toast({
							title: "Signup Successful",
							description: "Error assigning to company!",
							variant: "success",
						});
					}
				} else {
					toast({
						title: "Signup Successful",
						description: "You are now logged in.",
						variant: "success",
					});
				}

				// fetch user data
				const userResponse: Response<User> = await fetchWrapper("/v1/user", {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const newUrl = isCompanySignup
					? "/enterprise/welcome/"
					: `/insiders/welcome/?email=${userResponse.data.email}`;

				router.push(newUrl);
				router.refresh();
				return { token, user: userResponse.data };
			} else if ("message" in res) {
				toast({
					title: "Signup Failed",
					description: res.message,
					variant: "error",
				});
			}
		} catch (error) {
			if (error?.response?.status !== 422) throw error;
			const errors = error.response.data.errors;
			const serverErrors = {};

			for (const field in errors) {
				serverErrors[field] = error.response.data.errors[field][0];
			}

			setErrors(serverErrors);
		}
	};

	const login = async ({ code: companyCode, setErrors, ...props }: any) => {
		await csrf();
		setErrors({});

		try {
			const res: Response<any> = await fetchWrapper("/login", {
				method: "POST",
				body: JSON.stringify(props),
			});
			if (res.success) {
				const token = res.data.token;

				setCookie("token", token, { path: "/", maxAge: cookieAge });

				// Add user to organization
				if (token && companyCode) {
					try {
						const res = await addToOrganization(token, companyCode);
						//result.company = res.code
						deleteCookie("company-code");
					} catch (err) {
						toast({
							title: "Login Successful",
							description: "Error assigning to company!",
							variant: "success",
						});
					}
				} else {
					toast({
						title: "Login Successful",
						description: "You are now logged in.",
						variant: "success",
					});
				}

				// fetch user data
				const userResponse: Response<User> = await fetchWrapper("/v1/user", {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				mutate(() => true);
				router.push(redirectIfAuthenticated || "/");
				router.refresh();

				return { token, user: userResponse.data };
			} else {
				toast({
					title: "Login Failed",
					description: res.data.message,
					variant: "error",
				});
			}
		} catch (error) {
			//if (error?.response?.status !== 422) throw error;
			toast({
				title: "Login Failed",
				description: error.response.data.data,
				variant: "error",
			});

			const errors = error.response.data.errors;
			const serverErrors = {};

			for (const field in errors) {
				serverErrors[field] = error.response.data.errors[field][0];
			}

			setErrors(serverErrors);
		}
	};

	const forgotPassword = async ({
		setErrors,
		email,
	}: {
		setErrors: (errors: { [key: string]: string }) => void;
		email: string;
	}) => {
		await csrf();
		setErrors({});

		try {
			const res: Response<{ status: string }> = await fetchWrapper("/forgot-password", {
				method: "POST",
				body: JSON.stringify({
					email,
				}),
			});
			if (res) {
				toast({
					title: "Forgot Password",
					description: res?.status,
					variant: "success",
				});
				router.push("/");
			}
		} catch (error) {
			if (error?.response?.status !== 422) throw error;
			const errors = error.response.data.errors;
			const serverErrors = {};

			for (const field in errors) {
				serverErrors[field] = error.response.data.errors[field][0];
			}

			setErrors(serverErrors);
		}
	};

	const resetPassword = async ({ setErrors, ...props }: ResetPasswordProps) => {
		await csrf();
		setErrors({});

		const token = searchParams.get("token");
		const email = searchParams.get("email");
		const { password, confirmPassword } = props;

		try {
			const res: Response<{ status: string }> = await fetchWrapper("/reset-password", {
				method: "POST",
				body: JSON.stringify({
					email: email.trim().replace(" ", "+"),
					token,
					password,
					password_confirmation: confirmPassword,
				}),
			});
			if (res) {
				router.push("/login");
				setTimeout(
					() =>
						toast({
							title: "Reset Password",
							description: res.data.status,
							variant: "success",
						}),
					500
				);
			}
		} catch (error) {
			if (error?.response?.status !== 422) throw error;
			const errors = error.response.data.errors;
			const serverErrors = {};

			for (const field in errors) {
				serverErrors[field] = error.response.data.errors[field][0];
			}

			setErrors(serverErrors);
		}

		fetchWrapper<{ data: { status: string } }>("/reset-password", {
			method: "POST",
			body: JSON.stringify({ token, ...props }),
		})
			.then((response) => router.push("/login?reset=" + btoa(response.data.status)))
			.catch((error) => {
				if (error.response.status != 422) throw error;
				const errors = error.response.data.errors;
				const serverErrors = {};

				for (const field in errors) {
					serverErrors[field] = error.response.data.errors[field][0];
				}

				setErrors(serverErrors);
			});
	};

	const resendEmailVerification = ({ setStatus }: any) => {
		fetchWrapper<{ data: { status: string } }>("/email/verification-notification", {
			method: "POST",
		}).then((response) => setStatus(response.data.status));
	};

	const logout = async () => {
		if (!error) {
			deleteCookie("token");
			mutate(() => true);
		}

		window.location.pathname = "/login";
	};

	const addToOrganization = async (_token: string, code: string) => {
		const res: any = await fetchWrapper("/v1/organizations/member", {
			method: "POST",
			body: JSON.stringify({ code }),
			headers: {
				Authorization: `Bearer ${_token}`,
			},
		});
		return { code: res.data.name, message: res.data.message };
	};

	return {
		register,
		login,
		forgotPassword,
		resetPassword,
		resendEmailVerification,
		logout,
		addToOrganization,
	};
};

const userFetcher = async (url: string) => {
	const res = await fetch(process.env.NEXT_PUBLIC_API_URL + url, {
		headers: {
			Authorization: hasCookie("token") ? `Bearer ${getCookie("token")}` : "",
		},
	});

	if (!res.ok) {
		throw new Error("Couldn't fetch user data");
	}

	return res.json();
};

export function useUser() {
	const token = getCookie("token");
	const { data, error, isLoading } = useSWR(token ? ["/v1/user", token] : null, swrFetcher);

	return {
		user: data,
		isLoading,
		isError: error,
	};
}
